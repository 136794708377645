import styled from "styled-components";
import { COLORS } from "../assets/Colors";

export const BIVLogo = () => {
  return (
    <StyledLink target="_blank" href="https://brothers4tech.co.uk/">
      <Brothers>BROTHERS </Brothers> <WhiteBlock>IV TECH</WhiteBlock>
    </StyledLink>
  );
};

const WhiteBlock = styled.span`
  background-color: #ffffff;
  padding: 0px 4px;
  font-weight: 900;
  color: black;
  font-size: 15px;
`;

const StyledLink = styled.a`
  text-decoration: none;

  &:hover ${WhiteBlock} {
    transition: 300ms background-color ease-in-out;
    background-color: ${COLORS.secondary};
  }
`;

const Brothers = styled.span`
  color: #ffffff;
  font-size: 14px;
  font-weight: 900;
`;
